<template>
  <div class="news-page">
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="uid" width="100" label="编号" sortable="custom" />
        <el-table-column prop="realName" label="真实姓名" />
        <el-table-column prop="username" label="用户名" width="180" />
        <el-table-column prop="phone" label="电话" width="180" />
        <el-table-column prop="title" label="职位" width="180" />
        <el-table-column prop="regIp" label="注册ip" width="180" />
        <!-- <el-table-column
          prop="content"
          label="内容"
          width="300"
          :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        <!-- <el-table-column prop="picture" label="配图">
          <template slot-scope="ew">
            <img :src="ew.row.picture" alt="" style="height: 150px" />
          </template>
        </el-table-column> -->

        <el-table-column prop="createTime" label="时间" />
        <el-table-column prop="" label="状态">
          <template slot-scope="ew">
            <el-tag v-if="ew.row.status == 1" type="warning">启用</el-tag>
            <el-tag v-else type="warning">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="ew">
            <el-button
              v-show="$store.state.btn_if.indexOf('system_admin_edit') != -1"
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="skyblue"
              @click="edit(ew.row.uid)"
            >编辑</el-button>
            <el-button
              v-show="$store.state.btn_if.indexOf('system_admin_delete') != -1"
              type="text"
              icon="el-icon-delete"
              size="mini"
              class="red"
              @click="open(ew.row.uid)"
            >删除</el-button>

            <el-button
              v-if="ew.row.status == 1"
              v-show="$store.state.btn_if.indexOf('system_admin_status') != -1"
              type="text"
              icon="el-icon-setting"
              size="mini"
              class="yellow"
              @click="show(ew.row.uid, 2)"
            >禁用</el-button>
            <el-button
              v-show="$store.state.btn_if.indexOf('system_admin_status') != -1"
              v-else
              type="text"
              icon="el-icon-setting"
              size="mini"
              class="yellow"
              @click="show(ew.row.uid, 1)"
            >启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <el-dialog title="新增用户" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户">
          <el-input v-model="form.username" />
        </el-form-item>

        <el-form-item label="真实姓名">
          <el-input v-model="form.realName" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password" show-password />
        </el-form-item>
        <el-form-item label="职位">
          <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="注册ip">
          <el-input v-model="form.regIp" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="启用" :value="1" />
            <el-option label="禁用" :value="2" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false
            form = {}
          "
        >取 消</el-button>
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="资讯修改" :visible.sync="dialogVisiblexui" width="50%">
      <el-form ref="form" :model="form_1" label-width="80px">
        <el-form-item label="快讯标题">
          <el-input v-model="form_1.title" />
        </el-form-item>
        <el-form-item label="来源">
          <el-input v-model="form_1.source" />
        </el-form-item>
        <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :headers="header"
            :action="this.$store.getters.imgUrl"
            :on-success="handleAvatarSuccess_1"
            :data="action"
          >
            <img v-if="form_1.picture" :src="form_1.picture" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </el-form-item>
        <el-form-item label="内容">
          <el-input
            v-model="form_1.content"
            type="textarea"
            :rows="7"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblexui = false">取 消</el-button>
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="快讯列表" :visible.sync="diokuai" width="50%">
      <el-table
        :data="lista"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @selection-change="sel"
      >
        <!-- <el-table-column prop="id" width="100" label="编号"> </el-table-column> -->
        <el-table-column type="selection" width="55" />
        <el-table-column prop="title" label="标题" width="180">
          <template slot-scope="{ row }">
            <p>
              <a
                :href="`${baseUrl}/activity/${ew.row.id}.html`"
                target="“_blank”"
                style="  text-decoration: underline;"
              >{{ ew.row.title }}</a>
            </p>
            <a
              v-if="!!ew.row.otherUrl"
              :href="ew.row.otherUrl"
              target="“_blank”"
            >(原文链接)</a>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" width="380" />
        <el-table-column prop="source" label="来源" />
        <el-table-column prop="createTime" label="创建时间" sortable />
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPagea"
        :total="totala"
        :page-size="pagesizea"
        @size-change="handleSizeChangea"
        @current-change="handleCurrentChangea"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="diokuai = false">取 消</el-button>
        <el-button type="primary" @click="addskuai">{{
          btn ? '添 加' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // 111
  data() {
    return {
      action: {
        action: 'news'
      },
      btn: true,
      currentPage: 1,
      pagesize: 10,
      currentPagea: 1,
      pagesizea: 5,
      list: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      value: '',
      totala: 0,
      dialogVisible: false,
      dialogVisiblexui: false,
      staticUrl: process.env.VUE_APP_STATIC,
      total: 0,
      order: '',
      form: {
        status: '',
        regIp: '',
        username: '',
        title: '',
        password: '',
        realName: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: ''
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      diokuai: false,
      lista: [],
      fastid: ''
    }
  },
  updated() {
    //   console.log(this.form);
  },
  created() {
    this.getlist()
    sessionStorage.setItem('action', 'news')
  },
  methods: {
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'report_order_num'
        } else {
          s = 'am_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },

    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    handleSizeChangea(e) {
      this.pagesizea = e
      console.log(e)
    },
    handleCurrentChangea(e) {
      this.currentPagea = e
      this.getlista()
    },
    getlist() {
      this.$http
        .get('/admin/AuthMember/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            this.total = res.data.totalCount
          }
        })
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.picture = this.staticUrl + res.data.url
      } 
      console.log(file)
      //   console.log(imgurlbase)
    },
    sel(val) {
      console.log(val)
      var se = val.map(item => item.id)
      console.log(se)

      this.fastid = se.join(',')
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.picture = this.staticUrl + res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/AuthMember/add', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisible = false
              location.reload()
            } 
            this.btn = true
          })
      }
      this.btn = false
    },
    addedit() {
      if (this.btn) {
        this.form_1.picture = this.form_1.picture.slice(this.staticUrl.length)
        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisiblexui = false
              this.getlist()
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      // this
      sessionStorage.setItem('flag', false)
      this.$router.push({
        path: '/system_admin_add',
        query: {
          id: id
        }
      })
      // this.dialogVisiblexui = true
      // this.form_1.id = id
      // this.$http.get('/admin/News/getById?id=' + id).then(({ data: res }) => {
      //   if (res.errorCode == 200) {
      //     this.form_1 = res.data
      //   } else {
      //     this.$message.error(res.message)
      //
      //   }
      // })
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http.post('/admin/AuthMember/del', { uid: id }).then(res => {
            //   console.log(res)
            if (res.data.errorCode == 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              if (this.total % 10 == 1) {
                this.currentPage--
              }
              this.getlist()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    show(id, el) {
      console.log(id, el)
      this.$http
        .post('/admin/AuthMember/changeStatus', { uid: id, status: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          }
        })
    },
    addkuai() {
      this.diokuai = true
      this.getlista()
    },
    getlista() {
      this.$http
        .get('/admin/NewsTemp/getList', {
          params: { page: this.currentPagea, pageSize: this.pagesizea }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.lista = res.data.list
            this.totala = res.data.totalCount
            console.log(this.currentPagea)
            console.log(this.pagesizea)
          } 
        })
    },
    addskuai() {
      if (this.btn) {
        this.$http
          .post('/admin/News/addAll', { ids: this.fastid })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.diokuai = false
              location.reload()
            }
            this.btn = true
          })
      }
      this.btn = false
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
</style>
<style lang="less"></style>s
